import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function KontaktPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Timbuktu`, `Jason Diakité`, `kontakt`, `management`]}
        title="Kontakt"
      />
      <section className="kontakt space-y-6">
        <div>
          <h3>Management: Bad Taste Empire</h3>
          Björn Barnekow{" "}
          <a href="mailto:bjorn@badtasteempire.com">bjorn@badtasteempire.com</a>
        </div>{" "}
        <div>
          <h3>Skivbolag: EMI Sweden</h3>
          Henrik Hansson &nbsp;
          <a href="mailto:Henrik.hansson@umusic.com">
            Henrik.hansson@umusic.com
          </a>
          <br /> Hemsida:{" "}
          <a href="https://universalmusic.se/emi-sweden/">
            universalmusic.se/emi-sweden/
          </a>
          &nbsp;
        </div>
        <div>
          <h3>Press</h3>
          Lili Assefa <a href="mailto:lili@assefa.se">lili@assefa.se</a>
        </div>
        <div>
          <h3>Bokning Sverige: Luger</h3>
          <span>Daniel Bzdusek </span>
          <a href="mailto:daniel@luger.se">daniel@luger.se</a>
          &nbsp;
        </div>
        <div>
          <h3>Bokning Norge: Nordic Live</h3>
          Ottar Schanke Eikum 
          <a href="mailto:ottar@nordiclive.no">ottar@nordiclive.no</a>
          &nbsp;
        </div>
        <div>
          <h3>Förlag: Universal Music Publishing Scandinavia</h3>
          Tel: +46 (0)8 629 53 70 E-mail:{" "}
          <a
            href="mailto:ump.sweden@umusic.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            ump.sweden@umusic.com
          </a>{" "}
          <br /> Hemsida:{" "}
          <a href="http://www.universalmusicpublishing.se/">
            universalmusicpublishing.se
          </a>
          &nbsp;
        </div>
        <div>
          <h3>Webmaster</h3>
          <a href="mailto:info@timbuk.nu">info@timbuk.nu</a>
        </div>
      </section>
    </Layout>
  );
}

export default KontaktPage;
